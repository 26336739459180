import React from "react";
import CookieConsent from "react-cookie-consent";
export default function CookieBanner() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            style={{ background: "#133870", opacity: ".8" }}
            buttonStyle={{
                background: "#fff",
                color: "#133870",
                fontSize: "13px",
            }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
}
