import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { HashLoader } from "react-spinners";
import SkeletonText from "../skeletons/SkeletonText";
import Info from "../contact/Info";
import { getToken } from "../../api/api";
import { Link } from "react-router-dom";
import GlobalState from "../context/global-context";

const Contact = () => {
    const [contactText, setContactText] = useState([]);
    const [text, setText] = useState([]);
    const [action, setAction] = useState("");
    const globalState = useContext(GlobalState);
    const { language } = globalState;

    useEffect(() => {
        const userData =
            "YWRtaW46NTk5NDQ3MUFCQjAxMTEyQUZDQzE4MTU5RjZDQzc0QjRGNTExQjk5ODA2REE1OUIzQ0FGNUE5QzE3M0NBQ0ZDNQ==";
        const contact =
            'https://b2ng.bpower2.com/index.php/restApi/request/model/WwwPosts/params/{"link_id":7}';
        const text = `https://b2ng.bpower2.com/index.php/restApi/request/model/WwwPosts/params/{"id":91}`;

        getToken(userData).then((res) => {
            axios
                .get(contact, {
                    headers: {
                        Authorization: res.data.token,
                    },
                })
                .then((res) => {
                    let arr = [];
                    res.data.data.objects.map((item) => {
                        arr.push({
                            id: item.id,
                            en: item.post_content,
                        });

                        return null;
                    });

                    let contactArr = [];
                    contactArr.push(res.data.data.objects);
                    setTimeout(() => {
                        setAction(" text-list-grid");
                    }, 1);
                    return setContactText(contactArr);
                });
        });

        getToken(userData).then((res) => {
            axios
                .get(text, {
                    headers: {
                        Authorization: res.data.token,
                    },
                })
                .then((res) => {
                    setText(res.data.data.objects);
                });
        });
    }, []);

    return (
        <div className="contact">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <div className="container d-flex flex-wrap">
                        <li className="breadcrumb-item">
                            <Link to="/">
                                {language.navigation.text_1 ===
                                "Contact and Grants"
                                    ? "Home"
                                    : "Strona główna"}
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            {language.navigation.text_1}
                        </li>
                    </div>
                </ol>
            </nav>
            <div className="container">
                {text
                    ? text.map((e, key) => (
                          <>
                              <div key={key} className="text-center">
                                  {
                                      <div
                                          dangerouslySetInnerHTML={{
                                              __html: e.post_content,
                                          }}
                                      />
                                  }
                              </div>
                          </>
                      ))
                    : null}

                {contactText.length ? (
                    <div className={"text-list" + action}>
                        {contactText[0].map((item, index) => {
                            // switch (index) {
                            //     //title of content
                            //     case 0:
                            //         return <Info key={index} text={item} />;
                            //     //content
                            //     case 1:
                            //         return <Info key={index} text={item}/>;
                            //     //if add more content than we predict
                            //     default:
                            //         return <Info key={index} text={item}/>
                            // }
                            return item.id !== "91" ? (
                                <Info key={index} text={item} />
                            ) : null;
                        })}
                    </div>
                ) : (
                    <div>
                        <SkeletonText />
                        <div className="spinner-container">
                            <div className="spinner-position">
                                <HashLoader
                                    sizeUnit={"px"}
                                    size={70}
                                    margin={"6px"}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contact;
