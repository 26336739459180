import React from "react";
import euro from "../../assets/images/ue.png";
export default function Rules() {
    return (
        <div className="container rules">
            <div className="row justify-content-md-center">
                <div className="col-md-7 d-flex justify-content-center">
                    <img
                        className="regulation-euro-image w-100"
                        alt="dotation"
                        src={euro}
                    />
                </div>
                <div className="col-md-7 my-5">
                    <p className="font-weight-bold text-justify">
                        Projekt współfinansowany ze środków Dotacje dla
                        innowacyjnych MŚP, Regionalnego Programu Operacyjnego
                        Województwa Kujawsko-Pomorskiego na lata 2014-2020
                    </p>
                </div>
            </div>
        </div>
    );
}
